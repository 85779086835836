import { useEffect, useInsertionEffect, useState } from "react";
import "../../App.css";
//import { Auth } from "../../pages/auth";
import { signOut } from "firebase/auth";
import { db, auth, storage } from "../../config/firebase";
import {
  getDocs,
  collection,
  addDoc,
  getDoc,
  deleteDoc,
  updateDoc,
  doc,
  DocumentSnapshot,
} from "firebase/firestore";
import { listAll, ref, uploadBytes, getDownloadURL, list } from "firebase/storage";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";


export const DashboardApp = () => {
  const { isAuth } = useGetUserInfo();
  const [title, setTitle] = useState('');
  const [header1, setHeader1] = useState('');
  const [header2, setHeader2] = useState('');
  const submitRef = async () => {
   const clientRef = doc(db, `/clients/${auth?.currentUser?.email}/profile/`, "profile")
  
   try {
    const docSnap = await getDoc(clientRef);
    
    if (docSnap.exists()) {
        setTitle('Client Dashboard')
        setHeader1('Invoices Due')
        setHeader2('Announcements')
    } else {
      setTitle('Administrator Dashboard')
      setHeader1('Analytics')
      setHeader2('Client Activity')
    }

} catch (error) {
    console.error("Error checking document:", error);
    return false; // Handle error
}
  }

  submitRef();

  if (!isAuth) {

    return <Navigate to="/"  />;
    
  }


  return (
    <div className="App">
       
    <br/>

      <div class="header">
      <p> {title} </p>
      </div>
      <br/>
      <div className="leftColumn">
        <p> {header1} </p>
      </div>

      <div className="rightColumn">
        <p>{header2}</p>
        </div>



       </div>

         

  );
}

//export default App;
import { useEffect, useInsertionEffect, useState } from "react";
import "../../App.css";
//import { Auth } from "../../pages/auth";
import { signOut } from "firebase/auth";
import { db, auth, storage } from "../../config/firebase";
import {
  getDocs,
  collection,
  addDoc,
  getDoc,
  deleteDoc,
  updateDoc,
  doc,
  DocumentSnapshot,
} from "firebase/firestore";
import { listAll, ref, uploadBytes, getDownloadURL, list } from "firebase/storage";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";
import { getFunctions, httpsCallable } from "firebase/functions";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import { ConnectAccountOnboarding, ConnectComponentsProvider } from "@stripe/react-connect-js";


export const InvoiceApp = () => {
  const [email, setEmail] = useState('');
  const [stripeConnectInstance, setStripeConnectInstance] = useState();
  const [onboardingExited, setOnboardingExited] = useState(false);
  const [stripeAccount, setStripeAccount] = useState('');





const handleOnClick = async () => {
  
      const functions = getFunctions();
      const createStripeUserFunction = httpsCallable(functions, 'createStripeAccount'); 
      const createStripeSessionFunction = httpsCallable(functions, 'createStripeSession'); 
     
      const userDataVar = {
        email: auth?.currentUser?.email,
      }

      await createStripeUserFunction(userDataVar).then((result) => {

        console.log(result);
         
        
        setStripeAccount(JSON.stringify(result.data.id));
        
       
      }).catch((error) => {
      });

      await createStripeSessionFunction(stripeAccount).then((result) => {

      }).catch((error) => {

      });


}


  return (
    <div className="App">

      <br/>
      <div class="header">
      <p> Invoice </p>
      </div>
    <br/>
    <button onClick={handleOnClick}>Create Stripe Account</button>

    

    </div>

  );
}

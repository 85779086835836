import { useEffect, useInsertionEffect, useState } from "react";
import "../../App.css";
//import { Auth } from "../../pages/auth";
import { signOut } from "firebase/auth";
import { db, auth, storage } from "../../config/firebase";
import {
  getDocs,
  collection,
  addDoc,
  getDoc,
  deleteDoc,
  updateDoc,
  doc,
  DocumentSnapshot,
} from "firebase/firestore";
import { listAll, ref, uploadBytes, getDownloadURL, list } from "firebase/storage";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";


export const SupportApp = () => {
  const { isAuth } = useGetUserInfo();
  const [title, setTitle] = useState('');
  const [header1, setHeader1] = useState('');
  const [header2, setHeader2] = useState('');

  const submitRef = async () => {
   const clientRef = doc(db, `/clients/${auth?.currentUser?.email}/profile/`, "profile")
  }
   
  if (!isAuth) {

    return <Navigate to="/"  />;
    
  }


  return (
    <div className="App">
       
    <br/>

      <div class="header">
      <p> FormXS Support </p>
      </div>
      <br/>
      <div className="leftColumn">
        <p>Contact Us:</p>
      </div>

      <div className="rightColumn">
        <p>Frequently Asked Questions</p>
        </div>



       </div>

         

  );
}

//export default App;
import { useEffect, useInsertionEffect, useState } from "react";
import "../../App.css";
//import { Auth } from "../../pages/auth";
import { signOut } from "firebase/auth";
import { db, auth, storage } from "../../config/firebase";
import {
  getDocs,
  collection,
  addDoc,
  getDoc,
  deleteDoc,
  updateDoc,
  doc,
  DocumentSnapshot,
} from "firebase/firestore";
import { listAll, ref, uploadBytes, getDownloadURL, list } from "firebase/storage";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";


export const AccountApp = () => {
  const { isAuth } = useGetUserInfo();
  const [StreetAddress, setStreetAddress] = useState('');
  const [City, setCity] = useState('');
  const [State, setState] = useState('');
  const [ZipCode, setZipCode] = useState('');
  const [locationList, setLocationList] = useState([]);


  const locRef = collection(db, `/clients/${auth?.currentUser?.email}/profile/profile/locations`)
  const getLocations = async () => {
    try {
      const data = await getDocs(locRef);
      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setLocationList(filteredData);
    } catch (err) {
      console.error(err);
    }
  };


  const submitRef = async () => {
    const clientRef = doc(db, `/clients/${auth?.currentUser?.email}/profile/`, "profile")
    
    try {
     const docSnap = await getDoc(clientRef);
     if (docSnap.exists()) {
    
        getLocations();
      
     } else {
      document.getElementById("addressForm").style.display = "none";
     }
 
 } catch (error) {
     console.error("Error checking document:", error);
     return false; // Handle error
 }
   }
 
   submitRef();


  const handleClick = async () =>{
    const valRef = collection(db, `/clients/${auth?.currentUser?.email}/profile/profile/locations`)
    
    await addDoc(valRef,{
      StreetAddress:StreetAddress,
      City: City,
      State: State,
      ZipCode: ZipCode,
    })
  }


   
  if (!isAuth) {

    return <Navigate to="/"  />;
    
  }


  return (
    <div className="App">
       
    <br/>

      <div class="header">
      <p> Account Management </p>
      </div>
      <br/>
      <div className="leftColumn">
        <p>Email:</p>
        <p>Primary Phone:</p>
      </div>

      <div className="rightColumn">
        <p>Locations</p>
        <form onSubmit={handleClick} id="addressForm" >
        <br/>
        <input
          type="text"
          placeholder="Street Address"
          onChange={(e) => setStreetAddress(e.target.value)}
        />
        <br/>
        <input
          type="text"
          placeholder="City"
          onChange={(e) => setCity(e.target.value)}
        />
        <br/>
        <input
          type="text"
          placeholder="State"
          onChange={(e) => setState(e.target.value)}
        />
        <br/>
        <input
          type="text"
          placeholder="Zip Code"
          onChange={(e) => setZipCode(e.target.value)}
        />
        <br/>
        <button type="submit" className='loginbtn'>Add Location</button>
        <br/>
 
      </form>
      <br/>
 
      <div className="locationColumn">
        {locationList.map((loc) => (
          <div className="locationCard">
            <p> Street: {loc.StreetAddress} </p>
            <p> City: {loc.City} </p>
            <p> Zip Code: {loc.ZipCode} </p>
            <p> State: {loc.State} </p>
          </div>
        ))}
        </div>
      </div>
       </div>
  );
}

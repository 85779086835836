import { useEffect, useInsertionEffect, useState } from "react";
import "../../App.css";
//import { Auth } from "../../pages/auth";
import { signOut } from "firebase/auth";
import { db, auth, storage } from "../../config/firebase";
import {
  getDocs,
  collection,
  query, 
  where, 
  addDoc,
  deleteDoc,
  getDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { listAll, ref, uploadBytes, getDownloadURL, list } from "firebase/storage";
import { useNavigate, Navigate } from "react-router-dom";



 
export const WorkOrderApp = () => {
  const [movieList, setMovieList] = useState([]);
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [newReleaseDate, setNewReleaseDate] = useState(0);
  const [isNewMovieOscar, setIsNewMovieOscar] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState("");
  const [fileUpload, setFileUpload] = useState(null);
  const [ownerID, setOwnerId] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');

//set owner Id
  const getOwner = async () => {
     const docRef = doc(db, `/clients/${auth?.currentUser?.email}/profile/`, "profile")
     const dataDb = await getDoc(docRef)
     const ownerIdVar = dataDb.data().ownerId
     setOwnerId(ownerIdVar)

     //const docRefE = doc(db, `/users/${ownerIdVar}/email/`, "email")
     //const dataDbE = await getDoc(docRefE)
     //const ownerEmailVar = dataDbE.data().email
     //setOwnerEmail(ownerEmailVar)
   }

   useEffect(()=>{
    getOwner();
}, [])
  
//update active work order list
  const getActiveWorkOrders = async () => {
    const ownerIdString =  JSON.stringify(ownerID).replace(/['"]+/g, '');
    try {
      
      //Your first database query! query collection where user id is the same as the client id, so that only their own work orders display
    const workOrderCollectionRef = collection(db, `/users/${ownerIdString}/activeworkorders/`);
    const workorderquery = query(workOrderCollectionRef, where("userId", "==", auth?.currentUser?.uid))
      const data = await getDocs(workorderquery);

      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setMovieList(filteredData);
    } catch (err) {
      console.error(err);
    }
  };

    getActiveWorkOrders();
 

  const onSubmitWorkOrder = async () => {
    const ownerIdStrings =  JSON.stringify(ownerID).replace(/['"]+/g, '');
    try {
     
    const savedWorkOrderCollectionRef = collection(db, `/users/${ownerIdStrings}/activeworkorders/`);

      await addDoc(savedWorkOrderCollectionRef, {
        title: description,
        releaseDate: newReleaseDate,
        receivedAnOscar: isNewMovieOscar,
        userId: auth?.currentUser?.uid,
        client: auth?.currentUser?.email,
        location: location,
      });
      


    } catch (err) {
      console.error(err);
    }
  };

  const deleteMovie = async (id) => {
    const movieDoc = doc(db, `/${auth?.currentUser?.uid}/`, id);
    await deleteDoc(movieDoc);
  };

  //const updateMovieTitle = async (id) => {
  //  const movieDoc = doc(db, `/${auth?.currentUser?.uid}/`, id);
  //  await updateDoc(movieDoc, { title: updatedTitle });
  //};

 


  const uploadFile = async () => {
    if (!fileUpload) return;
    const filesFolderRef = ref(storage, `projectFiles/${auth?.currentUser?.uid}/${fileUpload.name}`);
  try {
    await uploadBytes(filesFolderRef, fileUpload);
  } catch (err) {
    console.error(err);
  }
  };

  const [images, setImages] = useState([]);

      

 

  return (
    <div className="App">
    <br/>
    <div class="header">
      <p> Work Order </p>
      </div>
    <br/>
    <br/>

    <div className="leftColumn">
      <div className="workOrder">
        <div class="box1">
        <input
          placeholder="Date"
          type="number"
          onChange={(e) => setNewReleaseDate(Number(e.target.value))} className="workorderTextBox2"
        /> 
        <br/>
        <input
          placeholder="Address"
          type="text"
          onChange={(e) => setLocation(e.target.value)} className="workorderTextBox2"
        />
        <br/>
        <br/>
        <textarea rows="4"
          placeholder="Description"
          onChange={(e) => setDescription(e.target.value)} className="workorderTextBox"
        />
        <br/>
        <input
          type="checkbox"
          checked={isNewMovieOscar}
          onChange={(e) => setIsNewMovieOscar(e.target.checked)}
        />
        <label>Urgent? </label>
        <br/>
        <button onClick={onSubmitWorkOrder} class="loginbtn"> Submit </button>
      <br/>
      <br/>
   </div>
      {/* commenting out image upload stuff for now
      <hr class="hrstyle" />
      <div>  
      <h1>Images</h1>
        <input type="file" onChange={(e) => setFileUpload(e.target.files[0])} />
        <button onClick={uploadFile}> Upload File </button>
      {images.map((url) => {
        return <img src={url} />;        
      })}
    </div>
      */}
    <div> 
    </div>
    </div>
</div>

  <div className="rightColumn">
    <div className="workOrderColumn">
        {movieList.map((movie) => (
           <div className="workOrderCard">
            <p style={{ color: movie.receivedAnOscar ? "green" : "red" }}>
              {movie.title}
            </p>
            <p> Date: {movie.releaseDate} </p>


            {/*
            <button onClick={() => deleteMovie(movie.id)}> Delete Note </button>
            <input
              placeholder="new title..."
              onChange={(e) => setUpdatedTitle(e.target.value)}
            />
            <button onClick={() => updateMovieTitle(movie.id)}> 
              {" "}
              Update Text
            </button>
              */}
          </div>          
        ))}
      </div>
      </div>

    </div>
  );
}

//export default App;
import { useEffect, useInsertionEffect, useState } from "react";
import "../../App.css";
//import { Auth } from "../../pages/auth";
import { signOut } from "firebase/auth";
import { db, auth, storage } from "../../config/firebase";
import {
  getDocs,
  collection,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { listAll, ref, uploadBytes, getDownloadURL, list } from "firebase/storage";
import { useNavigate, Navigate } from "react-router-dom";



 
export const SharedDocumentsApp = () => {
  const [fileUpload, setFileUpload] = useState(null);
  const uploadFile = async () => {
    if (!fileUpload) return;
    const filesFolderRef = ref(storage, `projectFiles/${auth?.currentUser?.uid}/${fileUpload.name}`);
  try {
    await uploadBytes(filesFolderRef, fileUpload);
  } catch (err) {
    console.error(err);
  }
  };

  const [images, setImages] = useState([]);

      const fetchImages = async () => {
        const imagesRef = ref(storage, `projectFiles/${auth?.currentUser?.uid}/`);
  
        try {
          const result = await listAll(imagesRef);
          const urls = await Promise.all(result.items.map(item => getDownloadURL(item)));
          setImages(urls);
        } catch(error) {
          console.error("Error fetching images", error);
        }
      }
  
      fetchImages();
   



  return (
    <div className="App">
      <br />
      <div>
      <div class="header">
      <p> Shared Files </p>
      </div>
      <br/>
      <br/>
        <input type="file" onChange={(e) => setFileUpload(e.target.files[0])} />
        <button onClick={uploadFile}> Upload </button>
      
      <br/>
      <br/>
      {images.map((url) => {
        return <img src={url} />;
        
      })}
    </div>
    <div> 

    </div>
    </div>


  );
}

//export default App;
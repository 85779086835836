import { useEffect, useInsertionEffect, useState } from "react";
import "../../App.css";
//import { Auth } from "../../pages/auth";
import { signOut } from "firebase/auth";
import { db, auth, storage } from "../../config/firebase";
import {
  getDocs,
  collection,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { listAll, ref, uploadBytes, getDownloadURL, list } from "firebase/storage";
import { useNavigate, Navigate } from "react-router-dom";
import CalendarDisplay from "../../components/calendar";


//used to be a function
export const ScheduleApp = () => {





  return (
    <div className="App">

      <br/>
      <div class="header">
      <p> Schedule </p>
      </div>
    <div className="CalendarApp" >

      <CalendarDisplay/>

    </div>

    </div>

  );
}

 
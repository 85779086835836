import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyCX5Jd33NH5P85yU1pinMBN5QfcGZlGuDc",
  authDomain: "formxs-38520.firebaseapp.com",
  projectId: "formxs-38520",
  storageBucket: "formxs-38520.appspot.com",
  messagingSenderId: "732568448256",
  appId: "1:732568448256:web:e9771cb3b7755fa7c2d7e5",
  measurementId: "G-5Y9KL955JL"
  
};




const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

export const db = getFirestore(app);

export const storage = getStorage(app);

import { useEffect, useInsertionEffect, useState } from "react";
import "../../App.css";
//import { Auth } from "../../pages/auth";
import { signOut } from "firebase/auth";
import { db, auth, storage } from "../../config/firebase";
import {
  getDocs,
  collection,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { listAll, ref, uploadBytes, getDownloadURL, list } from "firebase/storage";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { v4 } from "uuid";
import { generatedId } from "../../components/userId";
import { ProfilePage } from "../profilepage";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";

//used to be a function
export const ProfilesApp = () => {
    const [txt,setTxt] = useState('')
    const [email,setEmail] = useState('')
    const [img,setImg] = useState('')
    const [data,setData] = useState([])
    const [fileUpload, setFileUpload] = useState(null);
    const [clientId,setClientId] = useState('')
    const [selectedClientId,setSelectedClientId] = useState('')

    const { isAuth } = useGetUserInfo();

    const handleUpload = (e) =>{

      
        const clientId = v4()

        setClientId(clientId)
        const imgs = ref(storage, `projectFiles/${auth?.currentUser?.uid}/${clientId}`)
       

        uploadBytes(imgs, e.target.files[0]).then(data=>{
           
            getDownloadURL(data.ref).then(val=>{
                setFileUpload(val)
            })
        })
    }

    const handleClick = async () =>{
            const valRef = collection(db, `/users/${auth?.currentUser?.uid}/clients/`)
            
            await addDoc(valRef,{
              txtVal:txt,
              email:email,
              imgUrl:fileUpload,
              cid:clientId,
            })
            

            await setDoc(doc(db, `/users/${auth?.currentUser?.uid}/clients/${clientId}/profile/`, "profile"), {
              txtVal:txt,
              email:email,
              imgUrl:fileUpload,
              id:clientId,
            });

            //await setDoc(doc(db, `/users/${auth?.currentUser?.uid}/clients/`), {
            //  txtVal:txt,
           //   email:email,
            //  imgUrl:fileUpload,
           //   id:clientId,
           // });

            
          
    }


    const getData = async () =>{
        const valRef = collection(db, `/users/${auth?.currentUser?.uid}/clients/`)
        const dataDb = await getDocs(valRef)
        const allData = dataDb.docs.map(val=>({
          ...val.data(),
          id: val.id
        }))
        setData(allData)
   
    }

    useEffect(()=>{
        getData()
})

    
  
  const navigate = useNavigate();
  

  const deleteProfile = async (id) => {
    const profileDoc = doc(db, `/${auth?.currentUser?.uid}/`, id );
    await deleteDoc(profileDoc);
  };
  

  const openUser = (userIdOpen) => {
     
    navigate(`/profilepage/`, {state:{clientId:userIdOpen}});
    
};
if (!isAuth) {

  return <Navigate to="/"  />;
  
}



  return (


    <div className="App" >
      <br/>
      <div class="header">
      <p> Client Management </p>
      </div>
      
        <div className="leftColumn">
             <ul class="ul-users">
              {data.map(profile=><div class="profilecard">
                  <li class="li-users">
                        <img src={profile.imgUrl} height='100px' width='100px' />
                        <p class="displaytext">{profile.txtVal}</p>
                        <p class = "displaytext">{profile.email}</p>
                        <button type="submit" onClick={() => openUser(profile.cid)} id="submit">Open</button>

                        </li>                   
                    </div>
                    )}
             </ul>
             </div>

          <div className="rightColumn">

          <div class="addClient">
            Name/Company: <input onChange={(e)=>setTxt(e.target.value)} /><br/>
            e-mail: <input onChange={(e)=>setEmail(e.target.value)} /><br/>
            Profile Picture: <input type="file" onChange={(e)=> handleUpload(e)} />

             <button onClick={handleClick} className="addbtn">Add</button>

             </div>

             <br/>
             <br/>
             
             <div>
             Customize Client Portal
             </div>

          </div>
        
    
  </div>

  
  );

};

//export default App;
import { auth, googleProvider } from "../../config/firebase";
import { signInWithPopup, signInWithRedirect, isSignInWithEmailLink, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { useNavigate, Navigate } from "react-router-dom";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";
import { getAuth, createUserWithEmailAndPassword, signOut } from "firebase/auth";
import "./styles.css";
import { useState } from "react";
import {
  doc,
  setDoc,
DocumentSnapshot,
} from "firebase/firestore";
import { db } from "../../config/firebase";

export const Auth = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { isAuth } = useGetUserInfo();

    const signInWithGoogle = async () => {
    
    const results = await signInWithPopup(auth, googleProvider);
    
    const authInfo = {
      userId: results.user.uid,
      email: results.user.email,
      name: results.user.displayName,
      profilePhoto: results.user.photoURL,
      isAuth: true,
    };
    localStorage.setItem("auth", JSON.stringify(authInfo));

    await setDoc(doc(db, `/users/${auth?.currentUser?.uid}/email/`, "email"), {
      email:results.user.email,
      id:results.user.uid,
    });

    navigate("/dashboard");

  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    
 
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      const authInfo = {
        userId: userCredential.user.uid,
        email: userCredential.user.email,
        name: userCredential.user.displayName,
        profilePhoto: userCredential.user.photoURL,
        isAuth: true,
      };
      localStorage.setItem("auth", JSON.stringify(authInfo));
       


      navigate("/dashboard");
  }



  const checkIfUserSignup = async () => {
    if (isSignInWithEmailLink(auth, window.location.href)){
      
      const userCredential = await signInWithEmailAndPassword(auth, 'farah097@umn.edu', 'password');
  
        const authInfo = {
          userId: userCredential.user.uid,
          email: userCredential.user.email,
          name: userCredential.user.displayName,
          profilePhoto: userCredential.user.photoURL,
          isAuth: true,
        };
        localStorage.setItem("auth", JSON.stringify(authInfo));
         
        navigate("/dashboard");
  }
  }
  checkIfUserSignup();

 

  if (isAuth) {

    return <Navigate to="/dashboard"  />;
    
  }

  return (
    <div>

    

    <div className="login-page-left">
      <div className="headerbox">
      <h1>FormXS</h1>
      <br/>
      A web platform to manage clients, invoices, work orders and finances.
      </div>
      
      </div>


    <div className="login-header">
    <nav>
        <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#signin">Clients</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#signin">Sign In</a></li>
        </ul>
    </nav>
    </div>




      <div className="login-page-right">
        
      <br/>
      <div class="loginbox">
      <form onSubmit={handleSubmit} className='login-form'>
        <br/>
        <input
          type="email"
          placeholder="Email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br/>
        <input
          type="password"
          placeholder="Password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br/>
        <button type="submit" className='loginbtn'>Login</button>
        <br/>
      <hr className='hrstyle' />
    <br/>

      <button className="login-with-google-btn" onClick={signInWithGoogle}>
        {" "}
        Sign In With Google
      </button>
      </form>
      <br/>
      <br/>

    </div>
    
  
  </div>



    <div className="logincontentbox" id="home">
      A web platform to manage clients, invoices, work orders and finances.

    </div>
    <div className="logincontentbox2" id="clients">
      Providing businesses with client portals. 
    </div>

    <small className = "footer">&copy; Copyright 2024, FormXS.com</small>  
  </div>

  );
};

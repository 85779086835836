import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import { SidebarDataClient } from './SidebarDataClient';
import './Navbar.css';
import { IconContext } from 'react-icons';
import { signOut } from "firebase/auth";
import { db, auth, storage } from "../config/firebase";
import "../App.css";
import { useNavigate, Navigate } from "react-router-dom";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import {
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  getDocs,
  collection,
} from "firebase/firestore";


function Navbar() {
  const [sidebar, setSidebar] = useState(true);

  const showSidebar = () => setSidebar(!sidebar);

  const { isAuth } = useGetUserInfo();
  const navigate = useNavigate();

  const signUserOut = async () => {
    try {
      await signOut(auth);
      localStorage.clear();
      navigate("/");
    } catch (err) {
      console.error(err);
     
    }
    
    showSidebar();
  };

  //temporary set role function 
  const [role, setRole] = useState(['']);
  const setrolefunction = async () => {
    const clientRef = doc(db, `/clients/${auth?.currentUser?.email}/profile/`, "profile")
   
    try {
     const docSnap = await getDoc(clientRef);
     
     if (docSnap.exists()) {
         setRole(SidebarDataClient)
 
     } else {
      setRole(SidebarData)
     }
 
 } catch (error) {

 }
  }
  setrolefunction();

  //

  if (isAuth) {
    
     return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
  
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items'>
          <h1 class="nav-title">FormXS </h1>
          
          <h2 class="nav-title-small"> {auth?.currentUser?.email} </h2>
          
            {role.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
          <br/>
        <button onClick={signUserOut} className="footer2"> Sign Out </button>
        <small className = "footer">&copy; Copyright 2024, FormXS.com</small> 
        </nav>

        
      </IconContext.Provider>
    </>
  );
}
}

export default Navbar;

import { useEffect, useInsertionEffect, useState } from "react";
import "../../App.css";
//import { Auth } from "../../pages/auth";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, sendSignInLinkToEmail, isSignInWithEmailLink, signOut } from "firebase/auth";
import { db, auth, storage} from "../../config/firebase";
import {
  deleteDoc,
  doc,
  getDoc,
  where, 
  setDoc,
  getDocs,
  query,
  collection,
} from "firebase/firestore";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import emailjs from '@emailjs/browser';
import { v4 } from "uuid";
import { getFunctions, httpsCallable } from "firebase/functions";

export const ProfilePage = () => {
    const [txt,setTxt] = useState('')
    const [email,setEmail] = useState('')
    const [img,setImg] = useState('')
    const [data,setData] = useState([])
    const [userIdVar, setUserIdVar] = useState([''])
    const [clientEmail, setClientEmail] = useState('');
    const [clientRegistered, setClientRegistered] = useState(false);
    const [ownerId, setOwnerId] = useState('');
    const [invitedClient, setInvitedClient] = useState('');
    const [locationList, setLocationList] = useState([]);
    const [activeWorkOrders, setActiveWorkOrders] = useState([]);

    //get clientId from selected client on profiles page
   const location = useLocation();
   const selectedClientId = location.state.clientId
   

//get and display selected client
  const getData = async () =>{
    const getownerId = auth.currentUser.uid
    const valRef = collection(db, `/users/${auth?.currentUser?.uid}/clients/${selectedClientId}/profile/`)
    const dataDb = await getDocs(valRef)
    const allData = dataDb.docs.map(val=>({
      ...val.data(),
      id: val.id
    }))
    setData(allData)
    setOwnerId(getownerId)
  }

  useEffect(()=>{
      getData()
}, [])

    



  const navigate = useNavigate();
  const signUserOut = async () => {
    try {
      await signOut(auth);
      localStorage.clear();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  const deleteProfile = async (id) => {
    const profileDoc = doc(db, `/users/${auth?.currentUser?.uid}/clients/${selectedClientId}/profile/`, 'profile');
    await deleteDoc(profileDoc);

    navigate("/profiles");
  };


  //send client invite
  const sendInvite = async (e) => {
     e.preventDefault();
     const docRef = doc(db, `/users/${auth?.currentUser?.uid}/clients/${selectedClientId}/profile/`, 'profile')
     const dataDb = await getDoc(docRef)
     const useremail = await dataDb.data().email
     const clientpassword = v4()

    const handleSendSignInLink = async () => {
    const userDataVar = {
      email: useremail,
      password: v4()
    }
    const functions = getFunctions();
    
    const createUserFunction = httpsCallable(functions, 'createUser');

    await createUserFunction(userDataVar).then((result) => {

      const emaildata = {email: result.data.email, link: result.data.link}  
      emailjs.send('service_n0v6bdq', 'template_gjc3ya4', emaildata, {
        publicKey: 'u7rwcDS96Kc9Bd9nT',
      })
      .then(
        () => {      
      },
        (error) => {      
        },
      );
    }).catch((error) => {
    });
      
      
      
      //const userCredential = await createUserWithEmailAndPassword(auth, useremail, 'password')
      //sendEmailVerification(userCredential.user);
      
      //old method of sending signup link without password, which is not cusotmizable  
      //sendSignInLinkToEmail(auth, useremail, {
       //   url: 'http://localhost:3000/',
      //    handleCodeInApp: true,
       // }).then(() => {
      //    localStorage.setItem('email', useremail);
      //  }).catch(err=>{
          
       // })
    };
    
    handleSendSignInLink();
  
  await setDoc(doc(db, `/clients/${useremail}/profile/`, "profile"), {
    txtVal:txt,
    email:useremail,
    imgUrl:"fileUploadURL",
    id:selectedClientId,
    ownerId:ownerId, 
  });
};

//check if client account exists, if so then hide profile
const setClientEmailVar = async () => {
  const docRef = doc(db, `/users/${auth?.currentUser?.uid}/clients/${selectedClientId}/profile/`, 'profile')
  try {
   const dataDb = await getDoc(docRef)
   const useremail = dataDb.data().email
   setClientEmail(useremail)
   
  } catch (err) {
    console.error(err);
  }
};
 
setClientEmailVar();

const ifClientExists = async () => {
 try {
  const clientRef = await doc(db, `/clients/${clientEmail}/profile/`, 'profile')
  const docSnap = await getDoc(clientRef);
  
  if (docSnap.exists()) {
      
    setClientRegistered(true)
    document.getElementById("sendinvbtn").style.display = "none";


//get locations
    const locRef = collection(db, `/clients/${clientEmail}/profile/profile/locations`)
    const getLocations = async () => {
       try {
          const data = await getDocs(locRef);
          const filteredData = data.docs.map((doc) => ({
           ...doc.data(),
        id: doc.id,
      }));
      setLocationList(filteredData);
    } catch (err) {
      console.error(err);
    }
  };

  getLocations();


//get active work orders for client
  const getActiveWorkOrders = async () => {
    try {
    const workOrderCollectionRef = collection(db, `/users/${auth?.currentUser?.uid}/activeworkorders/`);
    const workorderquery = query(workOrderCollectionRef, where("client", "==", clientEmail)) 
      const data = await getDocs(workorderquery);

      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setActiveWorkOrders(filteredData);
    } catch (err) {
      console.error(err);
    }
  };

    getActiveWorkOrders();

  } else {
  }
} catch (error) {
  console.error("Error checking document:", error);
  return false; 
}
}

ifClientExists();




  return (
    <div className="App" >
      <br/>
   
      <ul class="ul-clients">
              {
                data.map(profile=>

                <div class="profilecardHeader">
                  <li class="li-clients">
                  <img src={profile.imgUrl} height='100px' width='100px' />
                        <p class="displaytext">{profile.txtVal}</p>
                        <p class = "displaytext">{profile.email}</p>
                        <button onClick={deleteProfile} class="displaybtn">Delete Client</button>
                        <button onClick={sendInvite} class="displaybtn" id="sendinvbtn">Send Portal Invite</button>
                        
                        </li> 
          
                    </div>     
                  )
              }
              <br/>
              <br/>
             
             </ul>
             <br/>
              <br/>
        

      <div class="wrapper">
      <div className="collapsible">
        <input type="checkbox" id="collapsible-head"></input>
        <label for="collapsible-head">Billing</label>
        <div class="collapsible-text">
        <p>Invoices Due:</p>
        <br/>
        <p>Billing History:</p>
        </div>
      </div>
      </div> 
      <br/>

      <div class="wrapper">
      <div className="collapsible">
        <input type="checkbox" id="collapsible-head2"></input>
        <label for="collapsible-head2">Work Orders</label>
        <div class="collapsible-text">
        <p>Active Work Orders</p>
        <div className="workOrderColumn2">
        {activeWorkOrders.map((workOrder) => (
           <div className="workOrderCard">
            <p style={{ color: workOrder.receivedAnOscar ? "green" : "red" }}>
              {workOrder.title}
            </p>
            <p> Date: {workOrder.releaseDate} </p>
          </div>          
        ))}
      </div>

        <br/>
        <p>Work Order History</p>
        </div>
      </div>
      </div> 
      <br/>
      
      <div class="wrapper">
      <div className="collapsible">
        <input type="checkbox" id="collapsible-head3"></input>
        <label for="collapsible-head3">Account Information</label>
        <div class="collapsible-text">
        <p>Primary E-Mail: {clientEmail}</p>
        <p>Primary Phone Number:</p>
        <p>Locations:</p>
        <div className="locationColumn">
        {locationList.map((loc) => (
          <div className="locationCard">
            <p> Street: {loc.StreetAddress} </p>
            <p> City: {loc.City} </p>
            <p> Zip Code: {loc.ZipCode} </p>
            <p> State: {loc.State} </p>
          </div>
        ))}
        </div>
        </div>
      </div>
      </div> 
      <br/>

      <div class="wrapper">
      <div className="collapsible">
        <input type="checkbox" id="collapsible-head4"></input>
        <label for="collapsible-head4">Shared Files</label>
        <div class="collapsible-text">
        <p>Shared Files</p>
        </div>
      </div>
      </div> 
      <br/>
  

  </div>
    
  );

}



import { useEffect, useInsertionEffect, useState } from "react";
import "../../App.css";
//import { Auth } from "../../pages/auth";
import { signOut } from "firebase/auth";
import { db, auth, storage } from "../../config/firebase";
import {
  getDocs,
  collection,
  setDoc,
  addDoc,
  deleteDoc,
  getDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { listAll, ref, uploadBytes, getDownloadURL, list } from "firebase/storage";
import { useNavigate, Navigate } from "react-router-dom";



 
export const WorkOrdersApp = () => {
  const [movieList, setMovieList] = useState([]);
  const [description, setDescription] = useState("");
  const [newReleaseDate, setNewReleaseDate] = useState(0);
  const [isNewMovieOscar, setIsNewMovieOscar] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState("");
  const [fileUpload, setFileUpload] = useState(null);
  const [savedWorkOrders, setSavedWorkOrders] = useState([]);
  const [ownerEmail, setOwnerEmail] = useState('');



  const workOrderCollectionRef = collection(db, `/users/${auth?.currentUser?.uid}/activeworkorders`);
  const savedWorkOrderCollectionRef = collection(db, `/users/${auth?.currentUser?.uid}/savedworkorders`);

//update active work order list
  const getActiveWorkOrders = async () => {
    try {
      const data = await getDocs(workOrderCollectionRef);
      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setMovieList(filteredData);
    } catch (err) {
      console.error(err);
    }
  };

    getActiveWorkOrders();

 
    const getSavedWorkOrders = async () => {
      try {
        const data = await getDocs(savedWorkOrderCollectionRef);
        const filteredData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setSavedWorkOrders(filteredData);
      } catch (err) {
        console.error(err);
      }
    };
  
      getSavedWorkOrders();



  const onSubmitWorkOrder = async () => {
    try {
      await addDoc(workOrderCollectionRef, {
        title: description,
        releaseDate: newReleaseDate,
        receivedAnOscar: isNewMovieOscar,
        userId: auth?.currentUser?.uid,
      });
 




      // send work order as email to owner based on owner Id
      // add work order to queue of active work orders on owner account
      // have client and admin versions of the workorder page 


      

    } catch (err) {
      console.error(err);
    }
  };

  const deleteMovie = async (id) => {
    const movieDoc = doc(db, `/users/${auth?.currentUser?.uid}/savedworkorders/`, id);
    await deleteDoc(movieDoc);
  };

  //const updateMovieTitle = async (id) => {
  //  const movieDoc = doc(db, `/${auth?.currentUser?.uid}/`, id);
  //  await updateDoc(movieDoc, { title: updatedTitle });
  //};


  const closeWorkOrder = async (id) => {
    const movieDoc = doc(db, `/users/${auth?.currentUser?.uid}/activeworkorders/`, id);
    const dataDb = await getDoc(movieDoc)
   
    await setDoc(doc(db, `/users/${auth?.currentUser?.uid}/savedworkorders/`, id), {
      title: dataDb.data().title,
      releaseDate: dataDb.data().releaseDate,
      receivedAnOscar: dataDb.data().receivedAnOscar,
      userId: dataDb.data().userId,
    });

    await deleteDoc(movieDoc);

  };

 


  const uploadFile = async () => {
    if (!fileUpload) return;
    const filesFolderRef = ref(storage, `projectFiles/${auth?.currentUser?.uid}/${fileUpload.name}`);
  try {
    await uploadBytes(filesFolderRef, fileUpload);
  } catch (err) {
    console.error(err);
  }
  };

  const [images, setImages] = useState([]);

      

 

  return (
    <div className="App">

    <br/>
      <div class="header">
      <p> Work Order Management </p>
      </div>
    <div className = "leftColumn">
        <p>Active Work Orders</p>
   
      <div className="workOrderColumn">
        {movieList.map((movie) => (
          <div className="workOrderCard">
            <p> Client: {movie.client} </p>
            <p> Location: {movie.location} </p>
            <p> Date: {movie.releaseDate} </p>
            <p style={{ color: movie.receivedAnOscar ? "green" : "red" }}>
              {movie.title}
            </p>

            <button onClick={() => closeWorkOrder(movie.id)}> Close work Order </button>
            
            {/*
            <input
              placeholder="new title..."
              onChange={(e) => setUpdatedTitle(e.target.value)}
            />
            <button onClick={() => updateMovieTitle(movie.id)}> 
              {" "}
              Update Text
              
            </button>
              */}
          </div>
        ))}
   </div>
      </div>

    <div className = "rightColumn">
        <p>Closed Work Orders</p>
 
      <div className="workOrderColumn"> 
        {savedWorkOrders.map((movie) => (
          <div className="workOrderCard">
             <p> Client: {movie.client} </p>
            <p> Location: {movie.location} </p>
            <p> Date: {movie.releaseDate} </p>
            <p style={{ color: movie.receivedAnOscar ? "green" : "red" }}>
              Description: {movie.title}
            </p>
        
            <button onClick={() => deleteMovie(movie.id)} > Delete Note </button>
            {/*
            <input 
              placeholder="new title..."
              onChange={(e) => setUpdatedTitle(e.target.value)}
            />
            */}
       

          </div>
        ))}
  

      </div>



    </div>
    </div>





  );
}

import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Auth } from "./pages/auth/index";
import { WorkOrderApp } from "./pages/workorder";
import { SheetsApp } from "./pages/sheets/index";
import Navbar from './components/Navbar';
import { ProfilesApp } from "./pages/profiles";
import { DashboardApp } from "./pages/dashboard";
import { ProfilePage } from "./pages/profilepage";
import { SharedDocumentsApp } from "./pages/sharedDocuments";
import { WorkOrdersApp } from "./pages/workorders";
import { AccountApp } from "./pages/account";
import { SupportApp } from "./pages/support";
import { ScheduleApp } from "./pages/schedule";
import { InvoiceApp } from "./pages/invoice";

function App() {
  
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Auth />} />
          <Route path="/*" element={<Auth />} />
          <Route path="/workorder" element={<WorkOrderApp />} />
          <Route path="/sheets" element={<SheetsApp />} />
          <Route path="/profiles" element={<ProfilesApp />} />
          <Route path="/dashboard" element={<DashboardApp />} />
          <Route path="/profilepage" element={<ProfilePage />} />
          <Route path="/sharedDocuments" element={<SharedDocumentsApp />} />
          <Route path="/workorders" element={<WorkOrdersApp />} />
          <Route path="/account" element={<AccountApp />} />
          <Route path="/support" element={<SupportApp />} />
          <Route path="/schedule" element={<ScheduleApp />} />
          <Route path="/invoice" element={<InvoiceApp />} />
        </Routes>
      </Router>
    </div>
  );
  

}



export default App;
